.navbar {
  background-color: #0640a8;
  --bs-navbar-padding-y: 0;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #1f1f1f;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: #ffebcd;
}

.schrift_slogan {
  padding-top: 4px;
  font-size: 90%;
  background-color: #0640a8;
  color: #ffffff;
}

.schrift_wohnmobile {
  font-size: 110%;
  padding-right: 10px;
  background-color: #0640a8;
  color: #ffffff;
}
