@media (orientation: landscape) {
  .jumbo {
    background: url("../../assets/CUBE_ERXLEBEN.jpg") no-repeat center;
    background-size: cover;
    color: #ccc;
    height: 600px;
    position: relativ;
    z-index: -2;
  }
}
@media (orientation: portrait) {
  .jumbo {
    background: url("../../assets/CUBE_ERXLEBEN.jpg") no-repeat center;
    background-size: cover;
    color: #ccc;
    height: 600px;
    position: relativ;
    z-index: -2;
  }
}

.overlay {
  background-color: #ffffff;
  opacity: 0.6;
  position: relativ;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

#box1 {
  padding-top: 7px;
  font-size: 1.5em;
  text-shadow: -2px 0 #0640a8, 0 2px #0640a8, 2px 0 #0640a8, 0 -2px #0640a8;
}
