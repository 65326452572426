.kleine_tabelle_uebersicht {
  line-height: 1.05em;
  font-size: 0.7em;
  color: #888888;
}

.kleine_tabelle_uebersicht_wert {
  font-weight: 400;
  color: rgb(00, 0, 0);
}
