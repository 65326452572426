.main-footer {
  background-color: #0640a8;
  color: #ffffff;
}

.main-footer-all {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #0640a8;
  color: #ffffff;
}
