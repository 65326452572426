.h4schrift {
  font-size: 250%;
  font-weight: 500;
}

.h3schrift {
  font-size: 130%;
  font-weight: 500;
}

.h6schrift {
  font-size: 105%;
  font-weight: 400;
}

.hoehetippschrift {
  font-size: 80%;
}

.labelfarbe {
  color: #888888;
}

.eilmeldung {
  font-size: 200%;
  color: #ff0000;
  font-weight: 400;
}
